import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function bomproduceordermainUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'category', label: '科目' },
    { key: 'name', label: '物料名称' },
    { key: 'code', label: '69码'},
    { key: 'specification', label: '单份规格数量' },
    { key: 'plan_qty', label: '计划数量' },
    { key: 'box_quantity_car', label: '*装车箱规'},
    { key: 'box_qty', label: '箱数' },
    { key: 'true_qty', label: '*生产数量' },
    { key: 'remark', label: '备注' },
    { key: 'cost', label: '成本价'},
    { key: 'actions', label: '操作' },
  ]

  // if (config.status===1){
  //   tableColumns.splice(tableColumns.length -1,1)
  // }

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('bomproduceordermain_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const dataArr = [];

  const searchList = (ctx, callback) => {
    store
      .dispatch('bomproduceordermain/searchProduce', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        produceId:config.produceId,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data
        if(list.length>0){
          list.forEach(item =>{dataArr.push(item)})
        }
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    dataArr,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
