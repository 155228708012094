<template>

  <div>

    <!-- Table Container Card -->


    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">



        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
          <!-- Search -->
          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                      v-model="searchKey"
                      class="d-inline-block mr-1"
                      placeholder="搜索关键字..."
              />
            </div>
          </b-col>
        </b-row>


      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        v-model="datalist"
        select-mode="multi"
        selectable
        @row-selected="onRowSelected"
      >


        <!-- Columns -->
        <template #cell(id)="data">
          [#{{ data.item.id }}]
        </template>


        <!-- Column: Type -->

        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}} {{toTime((data.item.create_time))}}
        </template>

        <template #cell(updator)="data">
          {{getCodeLabel('user', data.item.updator)}} {{toTime((data.item.update_time))}}
        </template>

        <template #cell(status)="data">
          {{getCodeLabel('bom_status', data.item.status)}}
        </template>

        <template #cell(name)="data">
         {{data.item.ext.product.name}}
        </template>

        <template #cell(code)="data">
          {{data.item.ext.product.code}}
        </template>

        <template #cell(box_quantity)="data">
          {{data.item.ext.product.box_quantity}}
        </template>

        <!-- Column: State -->





      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import bomUseList from './bomUseList'
import bomStore from '../bomStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton
  },

  data() {
    return {
      datalist:[],
      selected: [],
    }
  },
  props:{
    warehouse_id: {
      type: Number,
      default: "",
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    getSelected() {
      return this.selected
    },
  },
  setup(props) {
    // Register module
    const toast = useToast()
    if (!store.hasModule('bom')) store.registerModule('bom', bomStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('bom')) store.unregisterModule('bom')
    })


    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['bom/getCondition']
    })





    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = bomUseList(
            {
              config:props.warehouse_id
            }
    )

    return {
      // ...toRefs(state),
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime, 
     toDate,


    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
