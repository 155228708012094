<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2" v-if="readonly">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <!-- Search -->
          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end"   >
              <b-button
                      variant="primary"
                      @click="showBomProduct()"
              >
                <span class="text-nowrap">添加产品</span>

              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="changeData?dataInfo:searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        v-model="tableData"
      >

        <template #row-details="row"   >
          <!--<b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>

            <b-table striped hover  :items="row.item.ext.items" :fields="fields"
            >

              <template v-slot:cell(#)="row" >
                <div v-if="isNumber(row.item.type)===1" >
                   <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.bom_item_id]">
                  </b-form-checkbox>
                </div>
              </template>

              <template #cell(type)="data">
                <span>{{ isNumber(data.item.type) ===1 ? '原料':'副成品'  }}</span>
              </template>

              <template #cell(cost)="data">
                {{isNumber(data.item.cost)===0?"":addNumber(data.item.cost,0)}}
              </template>


              <!--后期需要自动计算数量-->
           <!--   <template #cell(plan_qty)="data">
                 <div  v-if="isNumber(data.item.type) ===1">
                   <span>{{(isNumber(row.item.plan_qty) * isNumber(row.item.ext.product.box_quantity) / data.item.box_quantity).toFixed(2) }}</span>
                 </div>
              </template>

               <template #cell(true_qty)="data">
                <div  v-if="isNumber(data.item.type) ===1">
                  <span>{{Math.ceil(isNumber(row.item.plan_qty) * isNumber(row.item.ext.product.box_quantity) / data.item.box_quantity) }}</span>
                </div>-->
              <template #cell(true_qty)="data">
                <div  v-if="isNumber(data.item.type) ===2 && data.item.can_change === 1">
                  <b-form-input
                          id="item_true_qty"
                          size="sm"
                          v-model="data.item.true_qty"
                          @change="checkQtyItem(data.item)"
                          type="number"
                          style="width: 80px"
                          :readonly="isNumber(status)===1"
                  />
                </div>
                <div v-else>
                  <b-form-input
                          v-model="data.item.true_qty"
                          type="number"
                          style="width: 80px"
                          readonly="true"
                  />
                </div>
              </template>


            </b-table>

          </b-card>
        </template>

        <!-- Columns -->
        <template #cell(category)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           v-if="status!==1"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
            />
            <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          主成品
        </template>


<!--        &lt;!&ndash; Column: Type &ndash;&gt;-->


        <template #cell(plan_qty)="data" v-if="status===1">
          <b-form-input
                  id="plan_qty"
                  size="sm"
                  v-model="data.item.plan_qty"
                  @change="checkPlanQty(data.item)"
                  type="number"
                  style="width: 80px"
          />
        </template>

        <template #cell(true_qty)="data" v-if="status!==1">
          <b-form-input
                  id="true_qty"
                  size="sm"
                  v-model="data.item.true_qty"
                  @change="checkQty(data.item)"
                  type="number"
                  style="width: 80px"
                  :readonly="isNumber(data.item.status) === 1"
          />
        </template>


        <template #cell(remark)="data" v-if="status!==1">
          <b-form-input
                  id="remark"
                  size="sm"
                  v-model="data.item.remark"
                  style="width: 80px"
                  :readonly="isNumber(data.item.status) === 1"
          />
        </template>

        <template #cell(box_quantity_car)="data"  v-if=" status===1 ">
          <b-form-input
                  id="box_quantity_car"
                  size="sm"
                  v-model="data.item.box_quantity_car"
                  @change="checkBoxCarQty(data.item)"
                  type="number"
                  style="width: 80px"
          />
        </template>

        <template #cell(box_qty)="data">
          {{isNumber(data.item.box_quantity_car)===0 ? 0 : Math.ceil(isNumber(data.item.plan_qty)/data.item.box_quantity_car)}}
        </template>


        <template #cell(name)="data">
          {{data.item.ext.product.name}}
        </template>

        <template #cell(specification)="data">
          {{data.item.ext.product.box_quantity}}
        </template>

        <template #cell(code)="data">
          {{data.item.ext.product.code}}
        </template>


        <!-- Column: State -->


        <!-- Column: Actions -->

        <template #cell(actions)="data">
          <b-dropdown
                  variant="link"
                  no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
              />
            </template>

            <div v-if="isNumber(data.item.status) === 0 && isNumber(status)!==1">
              <b-dropdown-item @click="completeItem(data.item)" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">完成</span>
              </b-dropdown-item>
            </div>

            <div v-if="isNumber(status)===1">
              <b-dropdown-item @click="deleteItem(data.item)" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">删除</span>
              </b-dropdown-item>
            </div>

            <div v-if="isNumber(data.item.status) === 1">
              <b-dropdown-item  >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">已完成</span>
              </b-dropdown-item>
            </div>

          </b-dropdown>
        </template>



      </b-table>

    </b-card>
    <b-modal
            id="addBomProduct"
            ok-title="确认"
            cancel-title="取消"
            centered
            size="lg"
            @ok="addBomProduct"
            no-close-on-backdrop
            no-close-on-esc
            title="添加主成品"
            ref="bom-product"
    >
      <bom-select  ref="selectBomProduct"  :warehouse_id="warehouse_id">

      </bom-select>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isNumber, isEmpty,addNumber
} from '@core/utils/filter'
import bomproduceordermainUseList from './bomproduceordermainUseList'
import bomproduceordermainStore from '../bomproduceordermainStore'
import BomSelect from "@/views/apps/bom/bomselect/BomList";
import XyInputButton from "@/views/components/xy/XyInputButton";
import draggable from "vuedraggable";
import {useToast} from "vue-toastification/composition";
import bomproduceorderStore from "@/views/apps/bomproduceorder/bomproduceorderStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
    BomSelect,
    draggable
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    checkboxClick(data) {
      let id = data.bom_item_id
      let index = this.checked.indexOf(id);
      if (index > -1) {
        data.is_sel=0
        this.checked.splice(index, 1);
      } else {
        this.checked.push(id);
        data.is_sel=1
      }
    },
  },
  props: {
    produce_id: {
      type: Number,
      default: 0
    },
    produce_no: {
      type: String,
      default: ""
    },
    readonly:{
      type: Number,
      default: 0
    },
    warehouse_id:{
      type: Number,
      default:0,
    },
    status:{
      type:Number,
      default:0
    },
    readonly:{
      type:Boolean,
      default:false
    }
  },

  data() {
    return {
      changeData:false,
      addDataArr:true,
      selected:[],
      dataInfo:[],
      tableData:[],
      checked: [],
      searchTerm:[],
      allchecked: true,
      indeterminate: false,
      isCheckedObj: {},
      fields: [
        {
          key: '#',
          label: '#',
        },
        {
          key: 'type',
          label: '科目',
        },
        {
          key: 'name',
          label: '物料名称',
        },
        {
          key: 'code',
          label: '69码',
        },
        {
          key: 'box_quantity',
          label: '单份规格数量',
        },
        {
          key: 'stock_qty',
          label: '库存数量',
        },
        {
          key: 'plan_qty',
          label: '计划数量',
        },
        {
          key: 'true_qty',
          label: '实际数量',
        },
        {
          key: 'cost',
          label: '成本价',
        },

      ],
    }
  },
  setup(props) {
    // Register module
    const toast = useToast()
    if (!store.hasModule('bomproduceordermain')) store.registerModule('bomproduceordermain', bomproduceordermainStore)
    if (!store.hasModule('bomproduceorder')) store.registerModule('bomproduceorder', bomproduceorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('bomproduceorder')) store.unregisterModule('bomproduceorder')
    })
    onUnmounted(() => {
      if (store.hasModule('bomproduceordermain')) store.unregisterModule('bomproduceordermain')
    })

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['bomproduceordermain/getCondition']
    })

    const checkQty = function (item) {
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.true_qty)) {
        toast.error('生产数量必须是正整数!')
        item.true_qty = 0
      }
    }

    const checkQtyItem = function (item) {
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.true_qty)) {
        toast.error('生产数量必须是正整数!')
        item.true_qty = 0
      }
    }

    const checkBoxCarQty = function (item) {
      if(item.plan_qty>0){
        const cRegExp = /^[0-9]\d*$/
        if (!cRegExp.test(item.box_quantity_car)) {
          toast.error('装车箱规必须是正整数!')
          item.box_quantity_car = 1

        }
      } else {
        toast.error('请先填写计划数量再填写装车箱规!')
        item.box_quantity_car = 1
        return false
      }
    }


    const checkPlanQty = function (item) {
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.plan_qty)) {
        toast.error('计划数量必须是正整数!')
        item.plan_qty = 0
      }
    }

    const showBomProduct = function () {
      this.changeData = true
      if(dataArr.length>0&&this.addDataArr){
        dataArr.forEach(item=>{this.dataInfo.push(item)})
      }
      this.addDataArr = false ;
      this.$refs['bom-product'].show()
    }

    const addBomProduct = function () {
      let data = this.$refs.selectBomProduct.selected
      // 添加
      if (data != null) {
        data.forEach(item =>{this.dataInfo.push(item)})
        //去重
        this.dataInfo = removeDataInfo(this.dataInfo)
      }
    }

    const  removeDataInfo = function(params){
      if(params.length>0){
        for(let i=0;i<params.length;i++){
          for(let j=i+1;j<params.length;j++){
            if(params[i].bom_id===params[j].bom_id){
              params.splice(j,1);
              j--;
            }
          }
        }
      }
      return params;
    }

    const deleteDataInfo = function (params,bom_id) {
      let newArr = [];
      if(params.length>0){
        for(let i=0;i<params.length;i++){
           if(params[i].bom_id===bom_id){
            continue
           }
           newArr.push(params[i])
        }
      }
      return newArr;
    }

    const completeItem = function (data) {
        let params = this.checked
        let ids = "";
        if(params.length>0){
          for(let i=0;i<params.length;i++){
              ids+=params[i]+","
          }
        }
        if (ids.length > 0) {
          ids = ids.substr(0,ids.length - 1);
          }else{
            toast.error("未选主原料")
            return
          }
      let dataInfo = data;
      let itemLength = dataInfo.ext.items.length
      let itemInfo = dataInfo.ext.items.filter(item => isNumber(item.type)===2&&isNumber(item.can_change)===1);
      if(itemInfo.length>0){
        let itemInfoLessZero = itemInfo.filter(item => isEmpty(item.true_qty))
        if(itemInfoLessZero.length>0){
            toast.error("副成品数量不能为空")
            return
        }
      }
      store.dispatch('bomproduceorder/completeItem', {
        dataInfo: dataInfo,
        ids:ids,
        itemLength:itemLength
      }).then(res =>{
        if (res.data.code === 0) {
          toast.success('数据已保存!')
          refetchData()
        }else {
          toast.error('数据保存异常，'+ res.data.data)
          refetchData()
        }
      })
    }

    const deleteItem = function(data){
      this.changeData = true
      let id = isNumber(data.main_id)
      let bomId = isNumber(data.bom_id)
      if(dataArr.length>0&&this.addDataArr){
        dataArr.forEach(item=>{this.dataInfo.push(item)})
      }
      this.addDataArr = false
      if(id!==0){
        store.dispatch('bomproduceorder/deleteItem', {
          id:data.main_id,
        }).then(res =>{
          if (res.data.code === 0) {
            toast.success('删除成功!')
          }else {
            toast.error('数据删除异常，'+ res.data.data)
          }
        })
      }
      this.dataInfo = deleteDataInfo(this.dataInfo,bomId)
    }

    const {
      searchList,
      dataArr,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = bomproduceordermainUseList(
            {produceId:props.produce_id,
                   status:props.status
             }
    )

    return {
      searchList,
      dataArr,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime, 
     toDate,
      fromChildren,
      checkQty,
      showBomProduct,
      addBomProduct,
      checkPlanQty,
      checkBoxCarQty,
      isNumber,
      removeDataInfo,
      completeItem,
      checkQtyItem,
      deleteItem,
      deleteDataInfo,
      addNumber
    }
  },
  created() {
    this.changeData = false
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
