<template>
  <b-card
    class="bomproduceorder-edit-wrapper"
  >
    <!-- form -->
    <b-form id="bomproduceorderForm" class="edit-form mt-2">


    <b-card header="基本信息" >
             <div v-if="!canModify">
               <b-row>
                 <!--生产仓库-->
                 <b-col
                         cols="12"
                         md="4"
                         class="mb-2"
                 >
                   <modal-select
                           label="生产仓库*"
                           type="input"
                           v-on:change="fromChildren($event,['warehouse_name','warehouse_id'])"
                           :params="['warehouse_name','warehouse_id']"
                           :value="bomproduceorder.warehouse_name"
                           modalName="仓库"
                           placeholder="点击选择仓库"
                   >
                   </modal-select>
                 </b-col>


                 <!--交货日期-->
                 <b-col md="4">
                   <b-form-group
                           label-cols="3"
                           label-cols-lg="3"
                           label="交货日期"
                           label-for="delivery_date"
                           label-size="sm"
                           class="mb-1 required"
                           :readonly="canModify"
                   >
                     <flat-pickr
                             v-model="bomproduceorder.delivery_date"
                             class="form-control"
                     />
                   </b-form-group>
                 </b-col>

                 <!--生产编号-->
                 <b-col md="4">
                   <b-form-group
                           label-cols="3"
                           label-cols-lg="3"
                           label="生产编号"
                           label-for="produce_no"
                           label-size="sm"
                           class="mb-1 required"
                   >
                     <b-form-input
                             id="outbound_no"
                             size="sm"
                             v-model="bomproduceorder.produce_no"
                             readonly
                     />
                   </b-form-group>
                 </b-col>

                 <!--标题-->
                 <b-col md="4">
                   <b-form-group
                           label-cols="3"
                           label-cols-lg="3"
                           label="标题"
                           label-for="title"
                           label-size="sm"
                           class="mb-1 required"
                   >
                     <b-form-input
                             id="title"
                             size="sm"
                             v-model="bomproduceorder.title"
                             :readonly="canModify"
                     />
                   </b-form-group>
                 </b-col>


                 <!--创建人-->
                 <b-col md="4">
                   <b-form-group
                           label-cols="3"
                           label-cols-lg="3"
                           label="创建人"
                           label-for="creator"
                           label-size="sm"
                           class="mb-1 required"
                   >
                     <b-form-input
                             id="creator"
                             size="sm"
                             v-model="bomproduceorder.creator_name"
                             readonly
                     />
                   </b-form-group>
                 </b-col>

                 <!--创建日期-->
                 <b-col md="4">
                   <b-form-group
                           label-cols="3"
                           label-cols-lg="3"
                           label="创建日期"
                           label-for="create_time"
                           label-size="sm"
                           class="mb-1 required"
                   >
                     <b-form-input
                             id="create_time"
                             size="sm"
                             v-model="bomproduceorder.create_time"
                             readonly
                     />
                   </b-form-group>
                 </b-col>



               </b-row>
             </div>

          <div v-if="canModify">
        <b-row>
          <!--生产仓库-->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="生产仓库"
                    label-for="warehouse_id"
                    label-size="sm"
                    class="mb-1 required"
            >
              <b-form-input
                      id="warehouse_id"
                      size="sm"
                      v-model="bomproduceorder.warehouse_name"
                      readonly
              />
            </b-form-group>
          </b-col>


          <!--交货日期-->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="交货日期"
                    label-for="delivery_date_t"
                    label-size="sm"
                    class="mb-1 required"
            >
              <b-form-input
                      id="delivery_date_t"
                      size="sm"
                      v-model="bomproduceorder.delivery_date"
                      readonly
              />
            </b-form-group>

          </b-col>

          <!--生产编号-->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="生产编号"
                    label-for="produce_no_t"
                    label-size="sm"
                    class="mb-1 required"
            >
              <b-form-input
                      id="outbound_no_t"
                      size="sm"
                      v-model="bomproduceorder.produce_no"
                      readonly
              />
            </b-form-group>
          </b-col>

          <!--标题-->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="标题"
                    label-for="title_t"
                    label-size="sm"
                    class="mb-1 required"
            >
              <b-form-input
                      id="title_t"
                      size="sm"
                      v-model="bomproduceorder.title"
                      :readonly="canModify"
              />
            </b-form-group>
          </b-col>


          <!--创建人-->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="创建人"
                    label-for="creator"
                    label-size="sm"
                    class="mb-1 required"
            >
              <b-form-input
                      id="creator_t"
                      size="sm"
                      v-model="bomproduceorder.creator_name"
                      :readonly="canModify"
              />
            </b-form-group>
          </b-col>

          <!--创建日期-->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="创建日期"
                    label-for="create_time"
                    label-size="sm"
                    class="mb-1 required"
            >
              <b-form-input
                      id="create_time_t"
                      size="sm"
                      v-model="bomproduceorder.create_time"
                      :readonly="canModify"
              />
            </b-form-group>
          </b-col>



        </b-row>
      </div>

     </b-card>


     <div class="card">
          <h6 class="card-header">物料信息</h6>
          <div class="card-body">
            <b-col>
               <bom-produce-order-main-list ref="itemListComplete" :readonly="readonly" :status="bomproduceorder.status" :warehouse_id="bomproduceorder.warehouse_id"  :produce_id="bomproduceorder.produce_id" :produce_no="bomproduceorder.produce_no"  v-if="bomproduceorder.status>0"   >
               </bom-produce-order-main-list>
            </b-col>
          </div>
        </div>


      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col md="12">
              <b-form-group
                      label-cols="1"
                      label-cols-lg="1"
                      label="备注"
                      label-for="remark"
                      label-size="sm"
                      class="mb-1"
              >
                <b-form-textarea
                        id="remark"
                        rows="3"
                        max-rows="6"
                        style="margin: 5px"
                        v-model="bomproduceorder.remark"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                      label-cols="1"
                      label-cols-lg="1"
                      label="附件"
                      label-for="attachments"
                      label-size="sm"
                      class="mb-1"
              >
                <attachment-upload  v-if="bomproduceorder.loaded"  :theme="'files'"
                                    :attachment_id="bomproduceorder.attachments"
                                    :id="bomproduceorder.attachments"
                                    :object_type="'bom'"
                                    :object_id="0"
                                    @change="onUploaded"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="card-footer text-right "  v-if="onlyRead">
          <b-col
                  cols="12"
                  class="mt-50"
          >
            <b-button
                    variant="primary"
                    class="mr-1"
                    @click="save(1)"
                    v-if="bomproduceorder.status === 1"
            >
              保存
            </b-button>

            <b-button
                    variant="primary"
                    class="mr-1"
                    @click="save(2)"
                    v-if="bomproduceorder.status === 1"
            >
              提交
            </b-button>

            <!---->
            <b-button
                    v-if=" bomproduceorder.status===2 || bomproduceorder.status ===3 "
                    variant="primary"
                    class="mr-1"
                    @click="completeAll"
            >
              全部完成
            </b-button>

            <b-button
                    variant="outline-secondary"
                    @click="cancel"
            >
              取消
            </b-button>
          </b-col>
        </div>
      </div>

    </b-form>
    <!--/ form -->
    <b-modal
            id="loadingModal"
            centered
            hide-header
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            ref="loadingModal"
    >
      <p class="my-4">单据生成中，请勿关闭浏览器!</p>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import bomproduceorderStore from './bomproduceorderStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second, isEmpty,isNumber} from '@core/utils/filter'
import WareHouseSelect from "@/views/apps/warehouse/warehouse-modal/WarehouseList";
import flatPickr from "vue-flatpickr-component";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import ModalSelect from "@/views/components/modal/ModalSelect";

import BomProduceOrderMainList from "@/views/apps/bomproduceordermain/bomproduceorder/BomProduceOrderMainList";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    WareHouseSelect,
    flatPickr,
    AttachmentUpload,
    ModalSelect,
    BomProduceOrderMainList
  },
  data() {
    return {
      id: ref(0),
      bomproduceorder: ref({}),
      readonly : false,
      canModify: true,
      onlyRead:true,
    }
  },
  methods:{

  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('bomproduceorder')) store.registerModule('bomproduceorder', bomproduceorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('bomproduceorder')) store.unregisterModule('bomproduceorder')
    })

    const edit = function() {
      store.dispatch('bomproduceorder/edit', {id: this.id}).then(res => {
        this.bomproduceorder = res.data.data
        this.bomproduceorder.create_time = toTime(this.bomproduceorder.create_time)
        this.bomproduceorder.delivery_date = toDate(this.bomproduceorder.delivery_date)
        this.bomproduceorder.creator_name = getCodeLabel('user',this.bomproduceorder.creator)
        console.log(this.bomproduceorder.status)
        if(this.bomproduceorder.status===1){
          this.readonly = true;
          this.canModify = false;
        }
      })
    }

    const view = function() {
      store.dispatch('bomproduceorder/view', {id: this.id}).then(res => {
        this.bomproduceorder = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function(index) {

      if(isEmpty(this.bomproduceorder.title)){
        toast.error("请填写标题")
        return
      }

      if(isNumber(this.bomproduceorder.warehouse_id)===0){
        toast.error("请选择仓库")
        return
      }

      let dataInfo = this.$refs.itemListComplete.tableData


      for(let i = 0; i< dataInfo.length ; i++){
        let itemLength = dataInfo[i].ext.items.length
        if(isNumber(dataInfo[i].plan_qty===0||isNumber(dataInfo[i].box_quantity_car)===0)){
          toast.error("计划数量和装车箱规是必填的！")
          return;
        }
        if(isNumber(dataInfo[i].main_id)===0){
          dataInfo[i].main_id = null
        }
        dataInfo[i].itemLength = itemLength
      }

      if(index===2){
        if(isNumber(dataInfo.length)===0){
          toast.error("没有选择BOM!")
          return
        }
        this.bomproduceorder.status = 2
      }else{
        this.bomproduceorder.status = 1
      }
      this.bomproduceorder.delivery_date = second(this.bomproduceorder.delivery_date)
      this.bomproduceorder.create_time = second(this.bomproduceorder.create_time)
      store.dispatch('bomproduceorder/save',
              {...this.bomproduceorder,
                dataInfo:dataInfo,
                dataInfoLength:dataInfo.length
              }
      ).then( res =>{
        if (res.data.code === 0) {
          toast.success('数据已保存!')
        }else {
          toast.error('数据保存异常，'+ res.data.data)
        }
        this.$router.push({ name: 'apps-bomproduceorder-list'});
      })
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.bomproduceorder[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const onUploaded = function (id, attachment, result) {
      this.bomproduceorder.attachments = result
    }


    const completeAll = function () {
      let dataInfo = this.$refs.itemListComplete.tableData
      if(dataInfo.length>0) {
         for (let i = 0; i < dataInfo.length; i++) {
                  //  通过 is_sel 重写 ids  传入后台  遍历
                  let data = dataInfo[i];
                  let ids = ""
                  if (isNumber(data.status) === 0) {
                    //未完成
                    let dataItem = data.ext.items;

                    let itemInfo = dataItem.filter(item => isNumber(item.type)===2&&isNumber(item.can_change)===1);
                    if(itemInfo.length>0){
                      let itemInfoLessZero = itemInfo.filter(item => isEmpty(item.true_qty))
                      if(itemInfoLessZero.length>0){
                        toast.error("副成品数量不能为空")
                        return
                      }
                    }
                    // 取勾选的主原料
                    for (let j = 0; j < dataItem.length; j++) {
                      let isSel = dataItem[j].is_sel;
                      let id = dataItem[j].bom_item_id;
                      if (isNumber(isSel) === 1) {
                        ids += id + ","
                      }
                    }
                    if (ids.length > 0) {
                      ids = ids.substr(0, ids.length - 1);
                    } else {
                      toast.error("未选主原料")
                      return
                    }

                  }
                }
      }
         var promise = Promise.resolve()
         for(let i = 0 ; i < dataInfo.length ; i++){
           this.$refs['loadingModal'].show()
             //  通过 is_sel 重写 ids  传入后台  遍历
            let data = dataInfo[i];
            let ids = ""
            if(isNumber(data.status)===0){
                //未完成
              let dataItem = data.ext.items;
              // 取勾选的主原料
              for (let j = 0 ; j < dataItem.length ; j++ ){
                   let isSel = dataItem[j].is_sel ;
                   let id = dataItem[j].bom_item_id ;
                   if(isNumber(isSel)===1){
                     ids+=id+","
                   }
              }
              if (ids.length > 0) {
                ids = ids.substr(0,ids.length - 1);
              }else{
                toast.error("未选主原料")
                return
              }
              let itemLength = dataItem.length
              promise = promise.then(() => {
                 return completeItem(data,ids,itemLength)
              })
            }
         }

         promise.then(() => {
            this.$refs['loadingModal'].hide()
            location.reload()
          })
      }




     //.then(response => resolve(response)).catch(error => reject(error))
    const completeItem = function (data,ids,itemLength) {
              return Promise.resolve(data).then((res) => {
                return new Promise((resolve, reject) => {
                  //出库
                  store.dispatch('bomproduceorder/completeItem', {
                    dataInfo: data,
                    ids:ids,
                    itemLength:itemLength
                  }).then(response =>{
                    if (response.data.code === 0) {
                      toast.success('数据已保存!')
                    }else {
                      toast.error('数据保存异常，'+ response.data.data)
                    }
                    resolve(res)
                  })
                })
              })
    }



    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      fromChildren,
      onUploaded,
      isNumber,
      completeAll,
      completeItem

    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.onlyRead = this.$route.query.onlyRead || true;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>